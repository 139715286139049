import { writable } from "svelte/store";

export const user = writable(null);
export const app = writable(null);
export const notices = writable(null);
export const analytics = writable({
  android: 0,
  ios: 0,
  prev: 0,
});
export let customizeData = writable({
  data: "",
});

let userCopy;
user.subscribe((value) => {
  userCopy = value;
});
const VITE_API_LINK = "https://api.qix.cloud/";
const VITE_AUTH_M_REDIRECT_URI = "https://api.qix.cloud/microsoftRedirectSign";
const VITE_AUTH_REDIRECT_URI = "https://api.qix.cloud/googleRedirectSign";
//const VITE_BASE_LINK = "https://qixcloud-chat.pages.dev";
const VITE_ORIGIN_LINK = "https://legalpal.app/sign";
export const auth = () => {
  return new Promise((resolve, reject) => {
    const urlParams = new URLSearchParams(window.location.search);
    let code = urlParams.get("token");
    let rtnk = urlParams.get("rtnk");

    if (rtnk) {
      localStorage.setItem("token", rtnk);
      //window.parent.location.href = VITE_ORIGIN_LINK;
    }

    if (code && code.length) {
      if (code.includes("microsoft_code_")) {
        code = code.replace("microsoft_code_", "");
        getTokenWithMicrosoftCode(code)
          .then((token) => {
            localStorage.setItem("token", token);
            window.parent.location.href = VITE_ORIGIN_LINK + "?rtnk=" + token;
            resolve();
          })
          .catch((err) => {
            console.error(err);
            window.parent.location.href = VITE_ORIGIN_LINK;
            reject();
          });
      } else {
        getTokenWithGoogleCode(code)
          .then((token) => {
            localStorage.setItem("token", token);
            window.parent.location.href = VITE_ORIGIN_LINK + "?rtnk=" + token;
            resolve();
          })
          .catch((err) => {
            console.error(err);
            window.parent.location.href = VITE_ORIGIN_LINK;
            reject();
          });
      }
    } else if (localStorage.getItem("token")) {
      getUserInfo(localStorage.getItem("token") || "")
        .then((info) => {
          user.set(info);
          resolve();
        })
        .catch((err) => {
          console.error(err);
          reject();
        });
    } else {
      resolve();
    }
  });
};

export const logout = () => {
  user.set(null);
  localStorage.removeItem("token");
  location.reload();
};

export const signInWithGoogle = () => {
  const rootUrl = "https://accounts.google.com/o/oauth2/v2/auth";
  const options = {
    redirect_uri: VITE_AUTH_REDIRECT_URI,
    client_id:
      "567227164069-n724qdj6qo5ri7u2dt922j8o6f536027.apps.googleusercontent.com",
    access_type: "offline",
    response_type: "code",
    prompt: "consent",
    scope: [
      "https://www.googleapis.com/auth/userinfo.email",
      "https://www.googleapis.com/auth/userinfo.profile",
      "https://www.googleapis.com/auth/calendar.app.created",
      "https://www.googleapis.com/auth/drive.file",
      "https://www.googleapis.com/auth/gmail.send",
    ].join(" "),
  };

  const url = `${rootUrl}?${new URLSearchParams(options).toString()}`;

  window.parent.location.href = url;
};

export const signInWithMicrosoft = () => {
  const rootUrl =
    "https://login.microsoftonline.com/common/oauth2/v2.0/authorize";
  const options = {
    redirect_uri: VITE_AUTH_M_REDIRECT_URI,
    client_id: "0c8ee278-e0c4-4f30-80d5-57f89fa76376",
    response_type: "code",
  };

  const url = `${rootUrl}?${
    new URLSearchParams(options).toString() +
    "&scope=profile+openid+email+offline_access+User.Read+Calendars.ReadWrite+Files.ReadWrite+Mail.Send+Mail.Send.Shared"
  }`;
  window.parent.location.href = url;
};

const getTokenWithGoogleCode = (code) => {
  return new Promise((resolve, reject) => {
    fetch(VITE_API_LINK + "sign/google", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        code,
        redirectUri: VITE_AUTH_REDIRECT_URI,
      }),
    })
      .then((res) => {
        if (res.status >= 300) {
          reject();
        } else {
          return res.json();
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch(() => {
        reject();
      });
  });
};

const getTokenWithMicrosoftCode = (code) => {
  return new Promise((resolve, reject) => {
    fetch(VITE_API_LINK + "sign/microsoft", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        code,
        redirect_uri: VITE_AUTH_M_REDIRECT_URI,
      }),
    })
      .then((res) => {
        if (res.status >= 300) {
          reject();
        } else {
          return res.json();
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch(() => {
        reject();
      });
  });
};

const getUserInfo = (token) => {
  return new Promise((resolve, reject) => {
    fetch(VITE_API_LINK + "auth", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        if (res.status >= 300) {
          reject();
        } else {
          return res.json();
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch(() => {
        reject();
      });
  });
};

export const getAnalytics = () => {
  return new Promise((resolve, reject) => {
    fetch(VITE_API_LINK + "analytics", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => {
        if (res.status >= 300) {
          reject();
        } else {
          return res.json();
        }
      })
      .then((res) => {
        analytics.set({
          android: res.android,
          ios: res.ios,
          prev: res.prev,
        });
        resolve();
      })
      .catch(() => {
        reject();
      });
  });
};

export const sendBase64FileToGoogleDrive = (file, fileMimeType) => {
  return new Promise((resolve, reject) => {
    fetch(VITE_API_LINK + "sendGoogleDriveBase64File", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: JSON.stringify({
        file,
        fileMimeType,
      }),
    })
      .then((res) => {
        if (res.status >= 300) {
          reject();
        } else {
          return res.json();
        }
      })
      .then((res) => {
        resolve(res ? res : "Error");
      })
      .catch(() => {
        reject();
      });
  });
};

export const sendBase64FileToOneDrive = (file, fileMimeType) => {
  return new Promise((resolve, reject) => {
    fetch(VITE_API_LINK + "sendOneDriveBase64File", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: JSON.stringify({
        file,
        fileMimeType,
      }),
    })
      .then((res) => {
        if (res.status >= 300) {
          reject();
        } else {
          return res.json();
        }
      })
      .then((res) => {
        resolve(res ? res : "Error");
      })
      .catch(() => {
        reject();
      });
  });
};
