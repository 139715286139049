<script>
  import { onMount, setContext, createEventDispatcher } from "svelte";
  import { fade } from "svelte/transition";
  import { key } from "./rightMenu.js";

  export let x;
  export let y;
  let wy = 0;

  // whenever x and y is changed, restrict box to be within bounds
  $: (() => {
    if (!menuEl) return;

    const rect = menuEl.getBoundingClientRect();
    //x = Math.min(window.innerWidth - rect.width, x);
    if (x > window.innerWidth - rect.width) x -= rect.width;
    //console.log(window.innerHeight, rect.height, y, wy);
    if (y - wy > window.innerHeight - rect.height) y -= rect.height;
  })(x, y);

  const dispatch = createEventDispatcher();

  setContext(key, {
    dispatchClick: () => dispatch("click"),
  });

  let menuEl;
  function onPageClick(e) {
    if (e.target === menuEl || menuEl.contains(e.target)) return;
    dispatch("clickoutside");
  }
</script>

<svelte:window on:click={onPageClick} bind:scrollY={wy} />

<div
  transition:fade={{ duration: 100 }}
  bind:this={menuEl}
  class="py-1 px-2"
  style="top: {y}px; left: {x}px;"
>
  <slot />
</div>

<style>
  div {
    position: absolute;
    display: grid;
    border: 1px solid #0003;
    box-shadow: 2px 2px 5px 0px #0002;
    background: white;
    z-index: 9;
  }
</style>
