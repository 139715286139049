<svelte:options immutable={true} />

<script>
  import { onMount, createEventDispatcher } from "svelte";
  import { clickOutside } from "./utils/clickOutside.js";
  export let items;
  export let type;
  export let placeholder;
  let searchKey = "";
  let displayDropdown = false;
  let select = false;
  const dispatch = createEventDispatcher();
  async function render() {}
  function onSelectKey() {
    if (type == "email") {
      dispatch("select", { item: { email: searchKey } });
    }
  }
  function onSelect(item) {
    // console.log("item", item);
    if (type == "email" || type == "case") {
      searchKey = item.email;
    } else {
      searchKey = item.label;
    }
    dispatch("select", { item });
  }
  function onDelete() {
    dispatch("delete");
  }
  function displayDropdownFunc() {
    displayDropdown = !displayDropdown;
  }
  function hideDropdownFunc() {
    displayDropdown = false;
  }
  onMount(render);
</script>

<div class="select-menu-wrap">
  <div>
    <input
      {placeholder}
      type="text"
      class="flex-grow bg-transparent py-1 px-2 w-full"
      style="border: 1px solid;border-radius: 5px;"
      bind:value={searchKey}
      on:click={displayDropdownFunc}
      use:clickOutside
      on:click_outside={hideDropdownFunc}
      on:blur={onSelectKey}
    />
  </div>
  <div class="overflow-y-auto py-1 select-menu" class:hidden={!displayDropdown}>
    {#each items as item}
      {#if type == "email" || type == "case"}
        {#if item.email != ""}
          {#if searchKey == "" || (searchKey != "" && item.email
                .toLowerCase()
                .includes(searchKey.toLowerCase()))}
            <div
              class="flex items-center rounded p-2 hover:bg-gray-100 dark:hover:bg-gray-600"
              on:click={() => onSelect(item)}
            >
              <span>{item.email}</span><img
                alt=""
                src="/flags/{item.language}.png"
              />
            </div>
          {/if}
        {/if}
      {:else if searchKey == "" || item.label
          .toLowerCase()
          .includes(searchKey.toLowerCase())}
        <div
          class="flex items-center rounded p-2 hover:bg-gray-100 dark:hover:bg-gray-600"
          on:click={() => onSelect(item)}
        >
          <span>{item.label}</span><img
            alt=""
            src="/flags/{item.language}.png"
          />
        </div>
      {/if}
    {/each}
  </div>
</div>

<style>
  .select-menu-wrap {
    position: relative;
  }
  .select-menu > div {
    justify-content: space-between;
    cursor: pointer;
  }
  .select-menu img {
    width: 20px;
  }
  .select-menu {
    max-height: 12rem;
    position: absolute;
    background: #fff;
    min-width: 100%;
  }
</style>
